import {Component, Input, Output, EventEmitter} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { BaseFormComponent } from '../../base/base-form.component';

import { Book } from '../book';
import { BookService } from '../book.service';
import { initBookFormGroup } from '../book-fields/book-fields.component';


/**
 * Book form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-book-form',
  templateUrl: './book-form.component.html',
  styleUrls: ['./book-form.component.css']
})
export class BookFormComponent extends BaseFormComponent<Book> {
  @Input() declare injectedRecordId: string; // Explicitly redeclare this as an input
  @Output() override onSubmitSuccessEmitter = new EventEmitter<Book>(); // Re-declare the output

  constructor(
    protected dataService: BookService,
  ) {
    super(Book, initBookFormGroup, dataService);
  }

  /* Override the onSuccess method to ensure proper event propagation
   */
  override onSuccess(): void {
    // Get the created or updated record.
    const emittedRecord = this.createdRecord !== undefined ? this.createdRecord : this.record;

    // Emit the event with the record
    this.onSubmitSuccessEmitter.emit(emittedRecord);

    // Show success notification for a duration, then reset.
    this.success = true;
    setTimeout(() => this.success = false, this.notifyTimeout);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      title = '', edition = '', authors = '', isbn = '', copyright_year = ''
    } = this.formComposite.value.record;

    // Get publisher name from auto-complete component.
    let publisher = '';
    const recordGroup = <FormGroup> this.formComposite.controls.record;
    const publisherControl = <FormControl>recordGroup.controls['publisher'];
    if (publisherControl.value) {
      if (publisherControl.value.hasOwnProperty('name')) {
        publisher = publisherControl.value['name'];
      }
    }

    // Format clipboard contents.
    this.clipboardText = [
      `Title: ${title}`,
      `Edition: ${edition}`,
      `Authors: ${authors}`,
      `ISBN: ${isbn}`,
      `Publisher: ${publisher}`,
      `Copyright: ${copyright_year}`,
    ].join('\n');
  }
}
