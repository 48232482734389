import { Component, Input, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseFieldsComponent } from '../../base/base-fields.component';
import { User } from '../../user/user';
import { Campus } from '../campus';


/**
 * A set of form fields to manipulate a campus object.
 * @example
 * <form [formGroup]="formComposite" (ngSubmit)="onSubmit()">
 *   <div formGroupName="campus">
 *     <app-campus-fields [group]="formComposite.controls.campus">
 *     </app-campus-fields>
 *   </div>
 *   <button type="submit">Submit</button>
 * </form>
 */
@Component({
  selector: 'app-campus-fields',
  templateUrl: './campus-fields.component.html',
  styleUrls: ['./campus-fields.component.css']
})
export class CampusFieldsComponent extends BaseFieldsComponent {
  @Input() declare injectedRecordId: string;  // Explicitly redeclare this as an input // Added to as attempt to fix missing buttons
  @Input() declare formGroup: FormGroup; // Added to as attempt to fix missing buttons
  @Input() campusUserList: User[] = [];

  /**
   * Returns true if the field name is in the readOnlyList list.
   * If the readOnlyList includes 'all_fields', will always return true.
   * @param {string} field - the formControlName of the field.
   */
  isReadOnly(field: string): boolean {

    // If user list is empty, set primary_contact  to read only.
    if (field === 'primary_contact') {
      if (!this.campusUserList) {
        return true;
      }
      if (this.campusUserList.length === 0 ) {
        return true;
      }
    }

    return super.isReadOnly(field);
  }
}


/**
 * Static function that returns a campus field formgroup.
 *
 * @param {Campus} campus - campus object to initialize fields with.
 * @returns {FormGroup} intialized set of campus form fields.
 */
export function initCampusFormGroup(campus: Campus): FormGroup {
  // Conditional validators.

  let primaryContactValidators = [];
  if (campus.id && campus.id !== '') {
    primaryContactValidators.push(Validators.required);
  }

  // Build the form.
  const fb = new FormBuilder();
  const form = fb.group({
    'id': [campus.id, [] ],
    'name': [campus.name, [ Validators.required ] ],
    'address1': [campus.address1, [ Validators.required ] ],
    'address2': [campus.address2, [ ] ],
    'city': [campus.city, [ Validators.required ] ],
    // @TODO: convert to autocomplete select.
    'state': [campus.state, [ Validators.required ] ],
    'postal_code': [campus.postal_code, [
      Validators.required,
      Validators.pattern('[0-9]{5}\(-[0-9]{4}\)?')
    ] ],
    // @TODO: convert to autocomplete select.
    'primary_contact': [campus.primary_contact, primaryContactValidators],
    'primary_contact_str': [campus.primary_contact_str, [ ] ],
    // @TODO: convert to autocomplete select.
    'campus_system': [campus.campus_system, [ ] ],
    'campus_system_str': [campus.campus_system_str, [ ] ],
  });

  return form;
}
