<form id="new-log" class="form-group">

  <mat-form-field>
    <input matInput
      id="new-log-entry"
      placeholder="New log entry"
      #newLogField
      [(ngModel)]="newLogMessage"
      [ngModelOptions]="{standalone: true}"
      />
  </mat-form-field>

  <button mat-raised-button
    type="submit"
    color="primary"
    [disabled]="!newLogMessage.length"
    (click)="onSubmitClicked()"
    >
    Submit
  </button>

</form>

  <div *ngIf="!logList">
    There are no logs associated with this request.
  </div>

  <div class="log-results"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScroll()">

    <ul class="list-group" *ngFor="let log of logList">
      <li class="list-group-item">

        <div class="list-group-item-heading">
          {{ log.user_str ? log.user_str : 'System' }} &#64; {{ log.created_date | date: 'short' }}
        </div>

        <div class="col-xs-offset-1">
          {{ log.message }}
        </div>

      </li>
    </ul>
  </div>
