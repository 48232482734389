import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CampusModule } from '../campus/campus.module';

import { AcademicTermService } from './academic-term.service';
import { AcademicTermRoutingModule } from './academic-term-routing.module';

import { AcademicTermCardComponent } from './academic-term-card.component';
import { AcademicTermFieldsComponent } from './academic-term-fields/academic-term-fields.component';
import { AcademicTermFormComponent } from './academic-term-form/academic-term-form.component';
import {
  AcademicTermDialogComponent, AcademicTermDialogContentComponent
} from './academic-term-dialog/academic-term-dialog.component';
import {
  AcademicTermDeleteDialogComponent, AcademicTermDeleteDialogContentComponent
} from './academic-term-delete-dialog/academic-term-delete-dialog.component';
import { AcademicTermListComponent } from './academic-term-list/academic-term-list.component';
import { AcademicTermDetailComponent } from './academic-term-detail/academic-term-detail.component';
import {AlertModule} from 'ngx-bootstrap/alert';


@NgModule({
    imports: [
    CommonModule,
    SharedModule,
    CampusModule,
    AcademicTermRoutingModule,
    AlertModule,
    ],
  exports: [
    AcademicTermCardComponent,
    AcademicTermFieldsComponent,
    AcademicTermListComponent,
    AcademicTermDetailComponent,
    AcademicTermFormComponent,
  ],
  declarations: [
    AcademicTermCardComponent,
    AcademicTermFieldsComponent,
    AcademicTermListComponent,
    AcademicTermDetailComponent,
    AcademicTermFormComponent,
    AcademicTermDialogComponent,
    AcademicTermDialogContentComponent,
    AcademicTermDeleteDialogComponent,
    AcademicTermDeleteDialogContentComponent,
  ],
  providers: [
    AcademicTermService,
  ],
})
export class AcademicTermModule { }
