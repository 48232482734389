import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';

import { FileFormat } from '../../file-format/file-format';


@Component({
  selector: 'app-format-control-group',
  templateUrl: './format-control-group.component.html',
  styleUrls: ['./format-control-group.component.css']
})
export class FormatControlGroupComponent implements OnInit {

  @Input() formatList: FileFormat[];
  selectedFormats: FileFormat[];

  @Output() onAddFormatClickedEmitter = new EventEmitter<FileFormat[]>();

  @ViewChild('formatSelect') formatSelect: { open: () => void; };

  constructor() { }

  ngOnInit() {
    this.formatSelect.open();
  }

  addFormatClicked() {
    this.onAddFormatClickedEmitter.emit(this.selectedFormats);
  }
}
