import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PublisherModule } from '../publisher/publisher.module';
import { BookModule } from '../book/book.module';

import { HoldingBookService } from './holding-book.service';
import { HoldingBookRoutingModule } from './holding-book-routing.module';
import { HoldingBookListComponent } from './holding-book-list/holding-book-list.component';
import { HoldingBookFormComponent } from './holding-book-form/holding-book-form.component';
import { HoldingBookFormCreateComponent } from './holding-book-form-create/holding-book-form-create.component';
import { HoldingBookFormUpdateComponent } from './holding-book-form-update/holding-book-form-update.component';
import { HoldingBookCreateComponent } from './holding-book-create/holding-book-create.component';
import {
  HoldingBookDeleteDialogComponent,
  HoldingBookDeleteDialogContentComponent
} from './holding-book-delete-dialog/holding-book-delete-dialog.component';
import {
  HoldingBookAllPublicDialogComponent,
  HoldingBookAllPublicDialogContentComponent
} from './holding-book-all-public-dialog/holding-book-all-public-dialog.component';
import { HoldingBookCardComponent } from './holding-book-card.component';


@NgModule({
  // schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    SharedModule,
    PublisherModule,
    BookModule,
    HoldingBookRoutingModule,
  ],
  exports: [
    HoldingBookListComponent,
    HoldingBookFormCreateComponent,
    HoldingBookFormUpdateComponent,
    HoldingBookCardComponent
  ],
  declarations: [
    HoldingBookListComponent,
    HoldingBookFormComponent,
    HoldingBookFormCreateComponent,
    HoldingBookFormUpdateComponent,
    HoldingBookCreateComponent,
    HoldingBookDeleteDialogComponent,
    HoldingBookDeleteDialogContentComponent,
    HoldingBookAllPublicDialogComponent,
    HoldingBookAllPublicDialogContentComponent,
    HoldingBookCardComponent
  ],
  providers: [
    HoldingBookService,
  ],
})
export class HoldingBookModule { }
