import {Component, Output, EventEmitter, Input} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { BaseFieldsComponent } from '../../base/base-fields.component';

import { UserPreference } from '../user';


@Component({
  selector: 'app-user-preference-fields',
  templateUrl: './user-preference-fields.component.html',
  styleUrls: ['./user-preference-fields.component.css']
})
export class UserPreferenceFieldsComponent extends BaseFieldsComponent {
  @Input() declare formGroup: FormGroup;
  @Output() onTogglePreferenceEmitter = new EventEmitter<string>();

  public onTogglePreference(prefName: string) {
    this.onTogglePreferenceEmitter.emit(prefName);
  }
}


/**
 * Static function that returns a user preferences field formgroup.
 *
 * @param {User} user - user object to initialize fields with.
 * @returns {FormGroup} intialized set of user preference form fields.
 */
export function initUserPreferenceFormGroup(user: UserPreference): FormGroup {
  // Build the form.
  const fb = new FormBuilder();
  const form = fb.group({
    // User information
    'id': [user.id, [] ],
    'notify_request_in_status_change': [user.notify_request_in_status_change, [] ],
    'notify_request_out_status_change': [user.notify_request_out_status_change, [] ],
    'notify_request_in_opened': [user.notify_request_in_opened, [] ],
    'notify_request_out_opened': [user.notify_request_out_opened, [] ],
    'notify_request_in_new_log': [user.notify_request_in_new_log, [] ],
    'notify_request_out_new_log': [user.notify_request_out_new_log, [] ],
    'notify_request_reminder': [user.notify_request_reminder, [] ],
  });

  return form;
}
