<mat-card class="register-card">

  <mat-card-title> Register </mat-card-title>

  <mat-card-content>

<div *ngIf="active">If you would like to register for AIMHub, please email <a href="mailto:support&#64;aimhub.org">support&#64;aimhub.org</a></div>

<!--    <div id="success-notification" class="alert alert-success" *ngIf="success">-->
<!--      A registration email has been sent to you.-->
<!--    </div>-->
<!--    <div id="failed-notification" class="alert alert-danger"-->
<!--         *ngIf="failed && failedNotification">-->
<!--      {{ failedNotification }}-->
<!--    </div>-->

<!--    <form [formGroup]="registerForm" *ngIf="active" (ngSubmit)="onSubmit()">-->

<!--      <mat-form-field class="register-field">-->
<!--        <input matInput placeholder="Email"-->
<!--            type="email"-->
<!--            formControlName="email"-->
<!--            required-->
<!--            >-->
<!--        <mat-error *ngIf="registerForm.controls.email.hasError('backend')">-->
<!--          {{ registerForm.controls['email'].errors['backend'] }}-->
<!--        </mat-error>-->
<!--        <mat-error *ngIf="registerForm.controls.email.hasError('required')">-->
<!--          Email address is <strong>required</strong>-->
<!--        </mat-error>-->
<!--        <mat-error *ngIf="registerForm.controls.email.hasError('email')-->
<!--                      && !registerForm.controls.email.hasError('required')">-->
<!--          Email address is <strong>invalid</strong>-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->

<!--      <div class="button-row">-->
<!--        <button mat-raised-button-->
<!--            color="primary"-->
<!--            type="submit"-->
<!--            [disabled]="!registerForm.valid || submitting"-->
<!--            >-->
<!--          Register-->
<!--        </button>-->
<!--      </div>-->
<!--    </form>-->

  </mat-card-content>

</mat-card>
