import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseFieldsComponent } from '../../base/base-fields.component';
import { FileFormat } from '../file-format';


@Component({
  selector: 'app-file-format-fields',
  templateUrl: './file-format-fields.component.html',
  styleUrls: ['./file-format-fields.component.css']
})
export class FileFormatFieldsComponent extends BaseFieldsComponent {
  @Input() formGroup: FormGroup = new FormGroup({}); // Added to as attempt to fix missing buttons
}


/**
 * Static function that returns a FileFormat field formgroup.
 *
 * @param {FileFormat} fileFormat - FileFormat object to initialize fields with.
 * @returns {FormGroup} intialized set of file format form fields.
 */
export function initFileFormatFormGroup(fileFormat: FileFormat): FormGroup {
  // Build the form.
  const fb = new FormBuilder();
  const form = fb.group({
    'id': [fileFormat.id, [] ],
    'name': [fileFormat.name, [ Validators.required ] ],
    'extension': [fileFormat.extension, [ Validators.required ] ],
    'for_holdings': [fileFormat.for_holdings, [] ],
  });

  return form;
}
