import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BaseFieldsComponent} from '../../base/base-fields.component';
import {Book} from '../book';


/**
 * A set of form fields to manipulate a book object.
 * @example
 * <form [formGroup]="formComposite" (ngSubmit)="onSubmit()">
 *   <div formGroupName="book">
 *     <app-book-formgroup [group]="formComposite.controls.book">
 *     </app-book-formgroup>
 *   </div>
 *   <button type="submit">Submit</button>
 * </form>
 */
@Component({
  selector: 'app-book-fields',
  templateUrl: './book-fields.component.html',
  styleUrls: ['./book-fields.component.css']
})
export class BookFieldsComponent extends BaseFieldsComponent {
  @Input() declare formGroup: FormGroup; // Added to as attempt to fix missing buttons
}


/**
 * Static function that returns a book field formgroup.
 *
 * @param {Book} book - book object to initialize fields with.
 * @returns {FormGroup} intialized set of book form fields.
 */
export function initBookFormGroup(book: Book): FormGroup {
  // Build the form.
  const fb = new FormBuilder();
  return fb.group({
    'id': [book.id, [],],
    'title': [book.title, [Validators.required,],],
    'isbn': [book.isbn, [Validators.required,],],
    'authors': [book.authors, [Validators.required,],],
    'edition': [book.edition, [],],
    'copyright_year': [book.copyright_year, [Validators.pattern('[0-9]{4}'),],],
    'publisher': [book.publisher, [],],
  });
}
