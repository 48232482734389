import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseCrudFormComponent } from '../../base/base-crud-form.component';

import { Campus } from '../../campus/campus';
import { CampusService } from '../../campus/campus.service';
import { Book } from '../../book/book';
import { BookService } from '../../book/book.service';
import { FileFormat } from '../../file-format/file-format';
import { FileFormatService } from '../../file-format/file-format.service';
import { HoldingBookSource } from '../../holding-book-source/holding-book-source';
import { HoldingBookSourceService } from '../../holding-book-source/holding-book-source.service';

import { HoldingBook } from '../holding-book';
import { HoldingBookService } from '../holding-book.service';


@Component({
  selector: 'app-holding-book-form',
  templateUrl: './holding-book-form.component.html',
  styleUrls: ['./holding-book-form.component.css']
})
export class HoldingBookFormComponent extends BaseCrudFormComponent<HoldingBook> implements OnInit {

  @Input() declare record: HoldingBook; // Explicit Overide
  @Input() declare serviceCall: string; // Explicit Overide

  campusList: Campus[];
  bookList: Book[];
  fileFormatList: FileFormat[];
  sourceList: HoldingBookSource[] = [];
  defaultSource: HoldingBookSource;
  defaultSourceName = 'Unspecified';

  @Input() successNotification: string;
  @Input() failedNotification: string;
  @Input() submitText: string;

  recordUrlPrefix = '/holding/book/detail/';

  formErrors = {
    'campus': '',
    'book': '',
    'file_format': '',
    'detail': '',
    'non_field_errors': '',
  };

  validationMessages = {
    'campus': {
      'required':      'Campus is required.',
    },
    'book': {
      'required':      'Book is required.',
    },
    'file_format': {
      'required':      'File format is required.',
    },
  };

  constructor(protected fb: FormBuilder,
              protected campusService: CampusService,
              protected bookService: BookService,
              protected fileFormatService: FileFormatService,
              protected holdingBookSourceService: HoldingBookSourceService,
              protected holdingBookService: HoldingBookService) {
    super(holdingBookService);
  }

  ngOnInit() {
    super.ngOnInit();
    console.log('HoldingBookFormComponent.........');
    this.getCampuses();
    this.getBooks();
    this.getFileFormats();
    this.getHoldingBookSources();

  }

  getCampuses() {
    this.campusService.list()
      .then(listData => {this.campusList = listData; });
  }

  getBooks() {
    this.bookService.list()
      .then(listData => {this.bookList = listData; });
  }

  getFileFormats() {
    this.fileFormatService.list()
      .then(listData => {
        this.fileFormatList = listData;
      })
      .catch(err => console.error('Error loading file formats', err));
  }

  getHoldingBookSources() {
    this.holdingBookSourceService.list()
      .then(listData => {
        this.sourceList = listData;
        this.getDefaultSource();
      });
  }

  getDefaultSource() {
    for (let i = 0; i < this.sourceList.length; i++) {
      if (this.sourceList[i].name === this.defaultSourceName) {
        this.defaultSource = this.sourceList[i];
      }
    }
  }

  buildFormGroup(): FormGroup {
    return this.fb.group({
      'campus': [this.record.campus, [
          Validators.required,
        ],
      ],
      'book': [this.record.book, [
          Validators.required,
        ],
      ],
      'file_format': [this.record.file_format, [
          Validators.required,
        ],
      ],
    });
  }

  initFormModel() {
    this.record = new HoldingBook('', '', '', '');
  }

  getFormModel() {
    const { campus, book, file_format } = this.recordForm.value;
    const source = this.defaultSource.id;
    const id = this.record.id;
    const submitHoldingBook = new HoldingBook(id, campus, book, file_format, source);
    return submitHoldingBook;
  }
}
