import {Component, Input, OnInit} from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { UserPreference } from '../user';
import { UserPreferenceService } from '../user-preference.service';
import { initUserPreferenceFormGroup } from '../user-preference-fields/user-preference-fields.component';
import {FormGroup} from "@angular/forms";


/**
 * User form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-user-preference-form',
  templateUrl: './user-preference-form.component.html',
})
export class UserPreferenceFormComponent extends BaseFormComponent<UserPreference> implements OnInit {
  @Input() declare injectedRecordId: string;

  constructor(
    protected dataService: UserPreferenceService,
  ) {
    super(UserPreference, initUserPreferenceFormGroup, dataService);
  }

  togglePreference(prefName: string) {
    const data = this.getRecordFields();
    this.update(data);
  }

}
