<div class="row">

  <div id="add-books" class="col-sm-6 panel panel-default">

    <div id="isbn-entry-notification">
      <alert type="success" *ngIf="success" [dismissible]="true">
        {{ bookList.length }} {{ bookList.length > 1 ? 'books' : 'book' }}
        added to the list
      </alert>

      <alert type="danger" [dismissible]="true" *ngIf="throttled">
        No more autofill queries are allowed. Books already in AIMHub can still be used.
      </alert>

      <alert type="danger" [dismissible]="true" *ngIf="isbnErrorList.length > 0">
        {{ isbnErrorList.length }} ISBN {{ isbnErrorList.length > 1 ? 'errors' : 'error' }}
      </alert>
    </div>

    <div id="isbn-entry-form">
      <form [formGroup]="recordForm"  *ngIf="active"  (ngSubmit)="onSubmit()">

        <label for="isbn">ISBNs to Add</label>
        <textarea
          id="isbn"
          class="form-control"
          rows="5"
          required
          formControlName="isbn"
          >
        </textarea>

        <button mat-raised-button
            type="submit"
            color="primary"
            [disabled]="!recordForm.valid || submitting"
            >
          Submit
        </button>

          <app-book-dialog
            (onSubmitSuccessEmitter)="emitSingleBook($event)"
            [buttonColor]="'primary'"
            [customButtonText]="true"
            >
          Manually Add <mat-icon>add_box</mat-icon>
        </app-book-dialog>

      </form>
    </div>
  </div>

  <div class="col-sm-6">
    <p>
      Enter a list of ISBNs, separated by whitespace or commas. AIMHub will get
      book metadata automatically, where it can.
    </p>
    <p>
      For books that it doesn't find, you can manually add them with the
      "Manually Add" button. After adding the book record, please enter the ISBN into the batch list to add your holdings.
    </p>

    <div id="isbn-entry-errors">
      <div *ngIf="isbnErrorList.length > 0" >
        <ol *ngFor="let errorMessage of isbnErrorList" class="list-group">
          <li class="list-group-item alert alert-danger">{{ errorMessage }}</li>
        </ol>
      </div>
    </div>
  </div>

</div>
