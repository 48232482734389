import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { BookModule } from '../book/book.module';

import { BatchIsbnComponent } from './batch-isbn.component';
import { BatchIsbnRoutingModule } from './batch-isbn-routing.module';
import { BatchIsbnFormComponent } from './batch-isbn-form/batch-isbn-form.component';
import { BatchIsbnHoldingFormComponent } from './batch-isbn-holding-form/batch-isbn-holding-form.component';
import { BatchIsbnListComponent } from './batch-isbn-list/batch-isbn-list.component';
import { BatchIsbnListItemComponent } from './batch-isbn-list-item/batch-isbn-list-item.component';
import { FormatControlGroupComponent } from './format-control-group/format-control-group.component';
import { FormatListComponent } from './format-list/format-list.component';
import { AlertModule } from 'ngx-bootstrap/alert';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        BookModule,
        BatchIsbnRoutingModule,
        AlertModule,
    ],
  exports: [
  ],
  declarations: [
    BatchIsbnComponent,
    BatchIsbnFormComponent,
    BatchIsbnHoldingFormComponent,
    BatchIsbnListComponent,
    BatchIsbnListItemComponent,
    FormatControlGroupComponent,
    FormatListComponent,
  ],
  providers: [
  ],
})
export class BatchIsbnModule { }
