import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BaseFieldsComponent} from '../../base/base-fields.component';
import {User} from '../../user/user';
import {AcademicTerm} from '../academic-term';


/**
 * A set of form fields to manipulate an AcademicTerm object.
 */
@Component({
  selector: 'app-academic-term-fields',
  templateUrl: './academic-term-fields.component.html',
  styleUrls: ['./academic-term-fields.component.css']
})
export class AcademicTermFieldsComponent extends BaseFieldsComponent {

  @Input() currentUser: User;
  @Input() formGroup: FormGroup = new FormGroup({}); // Added to as attempt to fix missing buttons

  seasons = [
    {value: 'Spring', viewValue: 'Spring'},
    {value: 'Summer', viewValue: 'Summer'},
    {value: 'Fall', viewValue: 'Fall'},
    {value: 'Winter', viewValue: 'Winter'},
  ];

  isSystemAdmin(): boolean {
    if (this.currentUser !== undefined) {
      if (this.currentUser.role === 'System Administrator') {
        return true;
      }
    }
    return false;
  }
}


/**
 * Static function that returns an AcademicTerm field formgroup.
 *
 * @param {AcademicTerm} academicTerm - object to initialize fields with.
 * @returns {FormGroup} intialized set of academic term form fields.
 */
export function initAcademicTermFormGroup(academicTerm: AcademicTerm): FormGroup {
  // Build the form.

  const fb = new FormBuilder();
  return fb.group({
    'id': [academicTerm.id, []],
    'year': [academicTerm.year, [Validators.required]],
    'season': [academicTerm.season, [Validators.required]],
    'session': [academicTerm.session, []],
    'campus': [academicTerm.campus, []],
    'campus_str': [academicTerm.campus_str, []],
  });
}
