import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { AcademicTerm } from '../academic-term';
import {FormGroup} from "@angular/forms";


@Component({
  selector: 'app-academic-term-dialog',
  template: `
            <button *ngIf="!iconOnly" type="button" mat-raised-button
                    [color]="buttonColor" (click)="openDialog()">
              <span *ngIf="!recordId">
                  Add <mat-icon>add_box</mat-icon>
              </span>
              <span *ngIf="recordId">
                  Edit <mat-icon>edit</mat-icon>
              </span>
            </button>

            <button *ngIf="iconOnly" type="button" mat-mini-fab
                    [color]="buttonColor" (click)="openDialog()">
              <span *ngIf="!recordId">
                <mat-icon>add</mat-icon>
              </span>
              <span *ngIf="recordId">
                <mat-icon>edit</mat-icon>
              </span>
            </button>
             `,
})
export class AcademicTermDialogComponent {
  @Input() formGroup: FormGroup = new FormGroup({}); // Added to as attempt to fix missing buttons

  @Input() recordId: string;
  @Input() iconOnly = false;
  @Input() buttonColor = '';

  @Output() onSubmitSuccessEmitter = new EventEmitter<AcademicTerm>();

  constructor(
    public dialog: MatDialog
  ) { }

  protected openDialog(): void {
    // Open the dialog with record ID.
    const ref = this.dialog.open(AcademicTermDialogContentComponent, {
      data: { recordId: this.recordId }
    });

    // Subscribe to the child emitter in code.
    // On success, forward the emitted record up the chain.
    const sub = ref.componentInstance.onSubmitSuccessEmitter.subscribe(
      (record) => this.onSubmitSuccess(record)
    );

    // Unsubscribe after close.
    ref.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  public onSubmitSuccess(record: AcademicTerm): void {
    this.onSubmitSuccessEmitter.emit(record);
  }
}


@Component({
  selector: 'app-academic-term-dialog-content',
  template: `
            <app-dialog-header>
              {{ (data.recordId ? 'Edit' : 'Create') }} Academic Term
            </app-dialog-header>

            <mat-dialog-content>
              <app-academic-term-form
                 [injectedRecordId]="data.recordId"
                 (onCloseClickedEmitter)="dialogRef.close()"
                 (onSubmitSuccessEmitter)="onSubmitSuccess($event)"
              >
              </app-academic-term-form>
            </mat-dialog-content>
            `,
})
export class AcademicTermDialogContentComponent {

  @Output() onSubmitSuccessEmitter = new EventEmitter<AcademicTerm>();

  constructor(
    public dialogRef: MatDialogRef<AcademicTermDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public onSubmitSuccess(record: AcademicTerm): void {
    this.onSubmitSuccessEmitter.emit(record);
    this.onCloseClicked();
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }
}
